import React from 'react'

export default function Home() {
  return (
    <section className='content-container'>
        <article>
            <img src="https://source.unsplash.com/random/?restaurant&1" alt=""></img>
        </article>
        <article>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Harum modi iusto omnis voluptatibus, porro, vel tenetur inventore dicta quidem voluptate necessitatibus! Temporibus vitae tenetur adipisci, tempore natus rem beatae dicta, obcaecati esse unde molestias quos, quam ad assumenda odio? Facere placeat autem doloribus quasi quam distinctio inventore nisi deserunt similique asperiores fugit recusandae possimus velit corporis eius vel voluptate ipsa dolores excepturi in, ab, dolore quo. Officiis laudantium eaque quasi possimus dignissimos qui ratione eligendi unde odit rerum id nulla debitis quisquam fugiat, corporis mollitia quidem vitae laboriosam nobis omnis illum veritatis. Harum perferendis, distinctio repudiandae mollitia minus aspernatur quam suscipit aperiam ipsum, explicabo doloremque neque doloribus ratione hic repellat nam sed corporis debitis ex pariatur, tempora inventore deleniti. Animi asperiores sapiente incidunt blanditiis veritatis. Esse id quisquam aperiam asperiores aliquid ullam pariatur sapiente exercitationem itaque dignissimos perspiciatis cupiditate dolorum incidunt quia perferendis, aliquam deserunt. Voluptatem dolores ex facilis quaerat!</p>
            <img src="https://source.unsplash.com/random/?restaurant&2" alt=""></img>
        </article>
    </section>
  )
}
