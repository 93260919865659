import React, {useState, useEffect} from 'react'
import Navbar from './Navbar'
import axios from 'axios'

export default function Header() {
  const [lgImage, set_lgImage] = useState([])
  const [bg, set_bg] = useState([])
  let API_URL = process.env.REACT_APP_API_URL
  let bground, logo

  useEffect(() => {
    axios({
      method: "get",
      url: `${API_URL}/backgrounds/`
    }).then(res => {
      set_bg(res.data);
    })
  },  [])

  bg.map( 
    item => bground = item.image
  )

  useEffect(() => {
    axios({
      method: "get",
      url: `${API_URL}/logos/`
    }).then(res => {
      set_lgImage(res.data);
    })
  },  [])

  lgImage.map( 
    item => logo = item.image
  )
  return (
    <header style={{ backgroundImage: `url(${bground})`}} >
      <img src={`${logo}`} key={`${logo}`} alt="Logo" />
      <Navbar/>
    </header>
  )
}