import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import CatPhoto from '../images/dessert1.jpg'
import axios from 'axios'

function CategoryCard({title, description, photo, id, price, unit}) {
    return (
        <section className='category-card'>
            <img key={id} src={photo} alt={photo} />
            <div className='card-content'>
                <h1>{title}</h1>
                <p>{description}</p>
            </div>
            <div className='card-numbers'>
                <h2>{price} Ft</h2>
                <h3>{`${unit.length === 0?"":"("+unit+")"}`}</h3>
            </div>
        </section>
    )
}

export default function Categories() {
    const {category} = useParams()
    const [selections, set_selections] = useState([])
    let API_URL = process.env.REACT_APP_API_URL
    // console.log(API_URL)
    
    useEffect(() => {
        axios({
          method: "get", 
          url: `${API_URL}/selections/`      
          
        }).then(res => {        
          set_selections(res.data)
        })
      }, [])

    return (
        <div className='content-container'>
           {
            selections.filter(item_data => item_data.category === category).map(
                item => 
                <CategoryCard title={item.title} photo={item.photo} id={item.id} description={item.description} price={item.price} unit={item.unit}/>
            )
           }
            
           
            
        </div>
    )
}
