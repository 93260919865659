import './css/App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Categories from './components/Categories';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom"

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header/>
          <Routes>
            {/* <Route path="/services/:category" element={<Services/>} /> */}
            <Route path="/categories/:category" element={<Categories/>} />
            <Route path="/about/" element={<About/>} />
            <Route path="/" element={<Home/>} />
          </Routes>
        <Footer/>
        
      </div>
    </BrowserRouter>
  );
}

export default App;
