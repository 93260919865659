import React, {useState, useEffect, Fragment} from 'react'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'

function MenuItems() {
  const location = useLocation()
  const [nav_linkek, set_nav_linkek] = useState([])
  let API_URL = process.env.REACT_APP_API_URL

  useEffect(() => {
    axios({
      method: "get",
      url: `${API_URL}/categories/`,
    }).then(res => {
      set_nav_linkek(res.data)
      //console.log(res.data)
    })
  },  [])

  return(
    <Fragment>
      <Link to="/" className={`nav-link ${location.pathname==="/"?"active":""}`}>
        Kezdőlap
      </Link>      
      {nav_linkek.map( nav_link =>      
        <Link to={`/categories/${nav_link.category_link}`} 
          className={`nav-link ${location.pathname===`/categories/${nav_link.category_link}`?"active":""}`}>
          {nav_link.category_name}
        </Link>        
      )}
    </Fragment>
  )
  
}

export default function Navbar() {
  const [mobile_menu, set_mobile_menu] = useState(false)

  return (
    <>
      <div className='nav-div'>
        <div className='nav-desktop'>
          <nav>
            <MenuItems/>  
          </nav>
        </div>
        <div className='nav-mobile'>
          <i class="fa fa-bars" aria-hidden="true" onClick={e => set_mobile_menu(!mobile_menu)}></i>
        </div>
      </div>
      
      {
        mobile_menu&&    
          <div className='mobile-menu'  onClick={e => set_mobile_menu(!mobile_menu)}>
            <MenuItems/>
            {/* <div className='close-menu-container'  onClick={e => set_mobile_menu(!mobile_menu)}/> 
            Ennek a pozíciójával játszani kell, hogy a menu alatt kezdődjön, különben a menu nem érzékrli 
            a klick-et, (ez van felette, vagyis kitakarja*/}
          </div>
      }
    </>
  )
}
