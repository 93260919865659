import React from 'react'

export default function About() {
  return (
    <div className='content-container about-container'>
      <section>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Excepturi ea eligendi tenetur libero perspiciatis commodi, tempora obcaecati, ut similique architecto repudiandae! Minima perspiciatis, laudantium sequi iste inventore laboriosam at quidem.</p>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Adipisci, error. Minus, veritatis harum.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam dolores saepe architecto, ratione odit aspernatur temporibus esse modi dolore ut voluptate deleniti officiis perspiciatis commodi quod repellendus autem qui sapiente numquam nisi ipsa? Nihil iusto, ducimus, exercitationem molestias cum omnis maxime dolore culpa aperiam modi ad sapiente, a ipsa illo.</p>
      </section>
    </div>
  )
}
