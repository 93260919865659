import React from 'react'

export default function Footer() {
  return (
    <footer>
        <section>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus ipsam temporibus labore praesentium, incidunt voluptas error culpa perferendis. Recusandae, voluptatum?</section>
        <section>Lorem ipsum dolor sit amet.</section>
        <section>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem ex, dolore sint perspiciatis a incidunt alias voluptatem sit iure blanditiis.</section>
    </footer>
  )
}
